define("ember-models-table/templates/components/models-table/themes/ember-paper/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RR/f8Whd",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"summary\"],[[30,0,[\"summary\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[30,0,[\"summary\"]]],[1,\"\\n  \"],[8,[39,4],[[24,0,\"clearFilters\"]],[[\"@disabled\",\"@onClick\"],[[52,[51,[30,0,[\"anyFilterUsed\"]]],\"disabled\"],[28,[37,6],[[30,0,[\"doClearFilters\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,7],null,[[\"@icon\"],[[30,0,[\"themeInstance\",\"clearAllFiltersIcon\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,0,[\"themeInstance\",\"clearAllFiltersMsg\"]]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"paper-button\",\"unless\",\"fn\",\"paper-icon\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/summary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});