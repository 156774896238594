define("ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RYKbirUY",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"summary\"],[[30,0,[\"summary\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,4],null,[[\"@formLayout\",\"@model\",\"@onSubmit\"],[\"inline\",[30,0],[30,0,[\"noop\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"label\"],[12],[1,[30,0,[\"summary\"]]],[13],[1,\"\\n    \"],[8,[39,5],[[16,0,[28,[37,6],[\"clearFilters \",[30,0,[\"themeInstance\",\"buttonLink\"]]],null]],[16,\"disabled\",[52,[51,[30,0,[\"anyFilterUsed\"]]],\"disabled\"]]],[[\"@onClick\",\"@type\"],[[28,[37,8],[[30,0,[\"doClearFilters\"]]],null],\"secondary\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"i\"],[15,0,[30,0,[\"themeInstance\",\"clearAllFiltersIcon\"]]],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,0,[\"themeInstance\",\"clearAllFiltersMsg\"]]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"bs-form\",\"bs-button\",\"concat\",\"unless\",\"fn\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/summary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});