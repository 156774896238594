define("ember-models-table/templates/components/models-table/row-select-all-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gdKyvrv6",
    "block": "[[[10,\"button\"],[15,\"onclick\",[30,0,[\"doToggleAllSelection\"]]],[15,0,[28,[37,0],[[30,0,[\"themeInstance\",\"buttonLink\"]],\" toggle-all\"],null]],[14,4,\"button\"],[12],[1,\"\\n  \"],[10,\"i\"],[15,0,[52,[28,[37,2],[[30,0,[\"selectedItems\",\"length\"]],[30,0,[\"data\",\"length\"]]],null],[30,0,[\"themeInstance\",\"selectAllRowsIcon\"]],[52,[30,0,[\"selectedItems\",\"length\"]],[30,0,[\"themeInstance\",\"selectSomeRowsIcon\"]],[30,0,[\"themeInstance\",\"deselectAllRowsIcon\"]]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"concat\",\"if\",\"is-equal\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/row-select-all-checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});