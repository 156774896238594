define("ember-models-table/templates/components/models-table/cell-content-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sWaecasm",
    "block": "[[[1,[28,[35,0],[[30,0,[\"record\"]],[30,0,[\"column\",\"propertyName\"]]],null]],[1,\"\\n\"]],[],false,[\"get\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/cell-content-display.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});