define("ember-models-table/templates/components/models-table/themes/ember-paper/expand-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tdU75geF",
    "block": "[[[41,[30,0,[\"isExpanded\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@onClick\",\"@iconButton\"],[[28,[37,2],[[30,0,[\"doCollapseRow\"]],[30,1],[30,2]],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],[[16,0,[30,0,[\"themeInstance\",\"collapseRowIcon\"]]]],[[\"@icon\"],[[30,0,[\"themeInstance\",\"collapseRowIcon\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],null,[[\"@onClick\",\"@iconButton\"],[[28,[37,2],[[30,0,[\"doExpandRow\"]],[30,1],[30,2]],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],[[16,0,[30,0,[\"themeInstance\",\"expandRowIcon\"]]]],[[\"@icon\"],[[30,0,[\"themeInstance\",\"expandRowIcon\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]],[18,3,null]],[\"@index\",\"@record\",\"&default\"],false,[\"if\",\"paper-button\",\"fn\",\"paper-icon\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/expand-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});