define("ember-models-table/templates/components/models-table/columns-hidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i3BrAduo",
    "block": "[[[10,\"td\"],[15,\"colspan\",[30,0,[\"columnsCount\"]]],[15,0,[30,0,[\"themeInstance\",\"noDataCell\"]]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[28,[37,3],null,[[\"columnsCount\",\"themeInstance\"],[[30,0,[\"columnsCount\"]],[30,0,[\"themeInstance\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,4],[[30,0,[\"themeInstance\",\"allColumnsAreHiddenMsg\"]]],null]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"html-safe\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/columns-hidden.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});