define("pix-ui/stories/pix-block.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.block = void 0;

  var block = function block(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
          <PixBlock @shadow={{shadow}}>
            Lorem ipsum
          </PixBlock>
        
      */
      {
        "id": "DpLaTgaw",
        "block": "[[[1,\"\\n    \"],[8,[39,0],null,[[\"@shadow\"],[[36,1]]],[[\"default\"],[[[[1,\"\\n      Lorem ipsum\\n    \"]],[]]]]],[1,\"\\n  \"]],[],false,[\"pix-block\",\"shadow\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.block = block;
  var argTypes = {
    shadow: {
      name: 'shadow',
      description: 'Ombre sur le bloc',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: 'light',
      control: {
        type: 'select',
        options: ['light', 'heavy']
      }
    }
  };
  _exports.argTypes = argTypes;
});