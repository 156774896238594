define("pix-ui/stories/pix-select.stories", ["exports", "@storybook/addon-actions"], function (_exports, _addonActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.searchableSelect = _exports.withLabel = _exports.Default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Template = function Template(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixSelect
              @id={{id}}
              @options={{options}}
              @onChange={{onChange}}
              @selectedOption={{selectedOption}}
              @emptyOptionLabel={{emptyOptionLabel}}
              @emptyOptionNotSelectable={{emptyOptionNotSelectable}}
              @isSearchable={{isSearchable}}
              @isValidationActive={{isValidationActive}}
              @label={{label}}
            />
          
      */
      {
        "id": "M8ZsNoww",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@id\",\"@options\",\"@onChange\",\"@selectedOption\",\"@emptyOptionLabel\",\"@emptyOptionNotSelectable\",\"@isSearchable\",\"@isValidationActive\",\"@label\"],[[36,1],[36,2],[36,3],[36,4],[36,5],[36,6],[36,7],[36,8],[36,9]]],null],[1,\"\\n    \"]],[],false,[\"pix-select\",\"id\",\"options\",\"onChange\",\"selectedOption\",\"emptyOptionLabel\",\"emptyOptionNotSelectable\",\"isSearchable\",\"isValidationActive\",\"label\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  var Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    options: [{
      value: '1',
      label: 'Figues'
    }, {
      value: '2',
      label: 'Bananes'
    }, {
      value: '3',
      label: 'Noix'
    }, {
      value: '4',
      label: 'Papayes'
    }, {
      value: '5',
      label: 'Fèves de chocolat'
    }, {
      value: '6',
      label: 'Dattes'
    }, {
      value: '7',
      label: 'Mangues'
    }, {
      value: '8',
      label: 'Jujube'
    }, {
      value: '9',
      label: 'Avocat'
    }, {
      value: '10',
      label: 'Fraises'
    }, {
      value: '11',
      label: 'Kaki'
    }],
    onChange: (0, _addonActions.action)('onChange')
  };
  var withLabel = Template.bind({});
  _exports.withLabel = withLabel;
  withLabel.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    id: 'pix-select-with-label',
    label: 'Ton fruit préféré: '
  });
  var searchableSelect = Template.bind({});
  _exports.searchableSelect = searchableSelect;
  searchableSelect.args = _objectSpread(_objectSpread({}, Default.args), {}, {
    emptyOptionNotSelectable: false,
    isSearchable: true,
    isValidationActive: true,
    placeholder: 'Fraises, Mangues...'
  });
  var argTypes = {
    options: {
      name: 'options',
      description: 'Les options sont représentées par un tableau d‘objet contenant les propriétés ``value`` et ``label``. ``value`` doit être de type ``String`` pour être que le champ puisse être cherchable',
      type: {
        name: 'object',
        required: true
      }
    },
    onChange: {
      name: 'onChange',
      description: 'Fonction à appeler si une option est sélectionnée',
      type: {
        required: true
      },
      control: {
        disable: true
      }
    },
    selectedOption: {
      name: 'selectedOption',
      description: 'Option sélectionnée',
      options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      control: {
        type: 'select'
      },
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    },
    emptyOptionLabel: {
      name: 'emptyOptionLabel',
      description: 'Texte à afficher si aucune option n‘est sélectionnée',
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    },
    isSearchable: {
      name: 'isSearchable',
      description: 'Rend le champ cherchable',
      control: {
        type: 'boolean'
      },
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: false
        }
      }
    },
    isValidationActive: {
      name: 'isValidationActive',
      description: 'Rend la bordure du champ vert au focus si la valeur de recherche match une option (c\'est à dire si l\'utilisateur a selectionné une option valable',
      control: {
        type: 'boolean'
      },
      type: {
        name: "boolean",
        required: false
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: false
        }
      }
    },
    emptyOptionNotSelectable: {
      name: 'emptyOptionNotSelectable',
      description: 'Rend le premier champ qui est vide non visible une fois sélectionné',
      control: {
        type: 'boolean'
      },
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: false
        }
      }
    },
    size: {
      name: 'size',
      description: '⚠️ **Propriété dépréciée** ⚠️ , désormais tous les éléments de formulaires feront 36px de hauteur.',
      options: ['big', 'small'],
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'small'
        }
      }
    },
    label: {
      name: 'label',
      description: 'Donne un label au champ, le paramètre @id devient obligatoire avec ce paramètre.',
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});