define("ember-models-table/templates/components/models-table/themes/ember-paper/cell-content-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oMwcNo1C",
    "block": "[[[44,[[28,[37,1],[[30,0,[\"record\"]],[30,0,[\"column\",\"propertyName\"]]],null]],[[[1,\"  \"],[8,[39,2],null,[[\"@value\",\"@onChange\"],[[30,0,[\"value\"]],[28,[37,3],[[28,[37,4],[[30,1]],null]],null]]],null],[1,\"\\n\"]],[1]]],[18,2,null]],[\"value\",\"&default\"],false,[\"let\",\"get\",\"paper-input\",\"fn\",\"mut\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/cell-content-edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});