define("ember-models-table/templates/components/models-table/themes/ember-paper/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TeDwEW5D",
    "block": "[[[8,[39,0],null,[[\"@label\",\"@options\",\"@selected\",\"@disabled\",\"@placeholder\",\"@onChange\"],[[30,0,[\"label\"]],[30,0,[\"options\"]],[30,0,[\"value\"]],[30,0,[\"disabled\"]],[30,0,[\"placeholder\"]],[30,0,[\"updateValue\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,2],[[30,1]],null],[[[1,\"    \"],[10,1],[15,\"aria-valuetext\",[30,1,[\"value\"]]],[12],[1,[30,1,[\"label\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,1]],[1,\"\\n\"]],[]]]],[1]]]]],[1,\"\\n\"],[18,2,null]],[\"opt\",\"&default\"],false,[\"paper-select\",\"if\",\"option-is-object\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});