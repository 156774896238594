define("ember-models-table/templates/components/models-table/global-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uruw/s6c",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[15,0,[28,[37,3],[[30,0,[\"themeInstance\",\"form\"]],\" globalSearch\"],null]],[12],[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"themeInstance\",\"formElementWrapper\"]]],[12],[1,\"\\n      \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,[30,0,[\"themeInstance\",\"searchLabelMsg\"]]],[13],[1,\" \"],[8,[39,4],[[16,1,[30,0,[\"inputId\"]]],[16,0,[28,[37,3],[[30,0,[\"themeInstance\",\"input\"]],\" filterString\"],null]],[16,\"placeholder\",[30,0,[\"themeInstance\",\"searchPlaceholderMsg\"]]],[24,4,\"text\"]],[[\"@value\",\"@enter\"],[[30,0,[\"value\"]],[30,0,[\"noop\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"globalFilterUsed\"]],[[[1,\"        \"],[10,\"i\"],[15,0,[29,[\"clearFilterIcon \",[30,0,[\"themeInstance\",\"clearFilterIcon\"]]]]],[14,\"role\",\"button\"],[15,\"aria-label\",[30,0,[\"themeInstance\",\"clearGlobalFilterMsg\"]]],[15,\"onclick\",[28,[37,5],[[30,0,[\"clearGlobalFilter\"]]],null]],[12],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"concat\",\"input\",\"fn\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/global-filter.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});