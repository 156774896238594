define("pix-ui/stories/pix-collapsible.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.multipleCollapsible = _exports.collapsible = void 0;

  var collapsible = function collapsible(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
          <PixCollapsible
            @title={{title}}
            @titleIcon={{titleIcon}}>
            <div>Contenu du PixCollapsible</div>
          </PixCollapsible>
          
      */
      {
        "id": "WlrKIXsP",
        "block": "[[[1,\"\\n    \"],[8,[39,0],null,[[\"@title\",\"@titleIcon\"],[[36,1],[36,2]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[12],[1,\"Contenu du PixCollapsible\"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-collapsible\",\"title\",\"titleIcon\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.collapsible = collapsible;

  var multipleCollapsible = function multipleCollapsible(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
          <div>
            <PixCollapsible
              @title="Titre A"
              @titleIcon={{titleIcon}}>
                <div>Contenu A</div>
            </PixCollapsible>
      
            <PixCollapsible
              @title="Titre B"
              @titleIcon={{titleIcon}}>
                <div>Contenu B</div>
            </PixCollapsible>
      
            <PixCollapsible
              @title="Titre C"
              @titleIcon={{titleIcon}}>
                <div>Contenu C</div>
            </PixCollapsible>
          </div>
          
      */
      {
        "id": "kO8cqTfN",
        "block": "[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@title\",\"@titleIcon\"],[\"Titre A\",[36,1]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[12],[1,\"Contenu A\"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[39,0],null,[[\"@title\",\"@titleIcon\"],[\"Titre B\",[36,1]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[12],[1,\"Contenu B\"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[39,0],null,[[\"@title\",\"@titleIcon\"],[\"Titre C\",[36,1]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[12],[1,\"Contenu C\"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"]],[],false,[\"pix-collapsible\",\"titleIcon\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.multipleCollapsible = multipleCollapsible;
  var argTypes = {
    title: {
      name: 'title',
      description: 'Intitulé du contenu du PixCollapsible',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: 'Titre du contenu à dérouler en cliquant'
    },
    titleIcon: {
      name: 'titleIcon',
      description: 'Ajoute l\'icône donnée en paramètre avant le titre du PixCollapsible',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: 'user'
    }
  };
  _exports.argTypes = argTypes;
});