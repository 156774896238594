define("ember-models-table/templates/components/models-table/row-group-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WKlfQgwi",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,3],[14,6,\"#\"],[15,\"onclick\",[28,[37,3],[[30,0,[\"doToggleGroupedRows\"]]],null]],[12],[1,[30,0,[\"groupedValue\"]]],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"fn\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/row-group-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});