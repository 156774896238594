define("ember-models-table/templates/components/models-table/row-filtering", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LrdEtA4z",
    "block": "[[[44,[[28,[37,1],null,[[\"shouldAddExtraColumn\",\"RowFilteringCell\"],[[30,0,[\"shouldAddExtraColumn\"]],[50,[30,0,[\"themeInstance\",\"rowFilteringCellComponent\"]],0,null,[[\"themeInstance\",\"data\",\"selectedItems\",\"expandedItems\",\"expandAllRows\",\"collapseAllRows\",\"toggleAllSelection\"],[[30,0,[\"themeInstance\"]],[30,0,[\"data\"]],[30,0,[\"selectedItems\"]],[30,0,[\"expandedItems\"]],[30,0,[\"expandAllRows\"]],[30,0,[\"collapseAllRows\"]],[30,0,[\"toggleAllSelection\"]]]]]]]]],[[[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[30,1]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"shouldAddExtraColumn\"]],[[[1,\"      \"],[10,\"th\"],[12],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"shownColumns\"]]],null]],null],null,[[[1,\"      \"],[8,[30,1,[\"RowFilteringCell\"]],null,[[\"@column\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null]],[]]]],[1]]]],[\"RowFiltering\",\"column\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/row-filtering.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});