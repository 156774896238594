define("ember-models-table/templates/components/models-table/row-select-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YtanwE1Y",
    "block": "[[[10,\"button\"],[15,0,[30,0,[\"themeInstance\",\"buttonLink\"]]],[15,\"onclick\",[28,[37,0],[[30,0,[\"doClickOnRow\"]],[30,0,[\"index\"]],[30,0,[\"record\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n  \"],[10,\"i\"],[15,0,[52,[30,0,[\"isSelected\"]],[30,0,[\"themeInstance\",\"selectRowIcon\"]],[30,0,[\"themeInstance\",\"deselectRowIcon\"]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"fn\",\"if\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/row-select-checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});