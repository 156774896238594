define("ember-bootstrap/version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerLibrary = registerLibrary;
  _exports.VERSION = void 0;
  var VERSION = "4.9.0";
  _exports.VERSION = VERSION;

  function registerLibrary() {
    Ember.libraries.register('Ember Bootstrap', VERSION);
  }
});