define("ember-models-table/templates/components/models-table/table-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R+skYOix",
    "block": "[[[44,[[28,[37,1],null,[[\"shouldAddExtraColumn\"],[[30,0,[\"shouldAddExtraColumn\"]]]]]],[[[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"tr\"],[12],[1,\"\\n\"],[41,[30,0,[\"shouldAddExtraColumn\"]],[[[1,\"        \"],[10,\"td\"],[12],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"visibleProcessedColumns\"]]],null]],null],null,[[[41,[30,2,[\"componentForFooterCell\"]],[[[1,\"          \"],[46,[30,2,[\"componentForFooterCell\"]],null,[[\"selectedItems\",\"expandedItems\",\"data\",\"mappedSelectedItems\",\"mappedExpandedItems\",\"mappedData\"],[[30,0,[\"selectedItems\"]],[30,0,[\"expandedItems\"]],[30,0,[\"data\"]],[52,[30,2,[\"propertyName\"]],[28,[37,8],[[30,2,[\"propertyName\"]],[30,0,[\"selectedItems\"]]],null]],[52,[30,2,[\"propertyName\"]],[28,[37,8],[[30,2,[\"propertyName\"]],[30,0,[\"expandedItems\"]]],null]],[52,[30,2,[\"propertyName\"]],[28,[37,8],[[30,2,[\"propertyName\"]],[30,0,[\"data\"]]],null]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,\"td\"],[12],[13],[1,\"\\n\"]],[]]]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]]],[1]]]],[\"TableFooter\",\"column\",\"&default\"],false,[\"let\",\"hash\",\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\",\"component\",\"map-by\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/table-footer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});