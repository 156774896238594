define("ember-models-table/templates/components/models-table/cell-content-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ouo+UmTY",
    "block": "[[[8,[39,0],[[16,0,[30,0,[\"themeInstance\",\"input\"]]],[24,4,\"text\"]],[[\"@value\"],[[28,[37,1],[[30,0,[\"record\"]],[30,0,[\"column\",\"propertyName\"]]],null]]],null],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"input\",\"get\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/cell-content-edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});