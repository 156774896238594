define("ember-models-table/templates/components/models-table/themes/ember-paper/expand-all-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IfOaN5K4",
    "block": "[[[8,[39,0],null,[[\"@onClick\",\"@iconButton\"],[[30,0,[\"doExpandAllRows\"]],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[16,0,[30,0,[\"themeInstance\",\"expandAllRowsIcon\"]]]],[[\"@icon\"],[[30,0,[\"themeInstance\",\"expandAllRowsIcon\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,\"br\"],[12],[13],[1,\"\\n\"],[8,[39,0],null,[[\"@onClick\",\"@iconButton\"],[[30,0,[\"doCollapseAllRows\"]],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[16,0,[30,0,[\"themeInstance\",\"collapseAllRowsIcon\"]]]],[[\"@icon\"],[[30,0,[\"themeInstance\",\"collapseAllRowsIcon\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"paper-button\",\"paper-icon\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/expand-all-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});