define("pix-ui/stories/pix-input-code.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.Default = _exports.Template = void 0;

  var Template = function Template(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixInputCode
              @ariaLabel={{ariaLabel}}
              @inputType={{inputType}}
              @numInputs={{numInputs}}
            />
          
      */
      {
        "id": "RAKbxPj/",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@ariaLabel\",\"@inputType\",\"@numInputs\"],[[36,1],[36,2],[36,3]]],null],[1,\"\\n    \"]],[],false,[\"pix-input-code\",\"ariaLabel\",\"inputType\",\"numInputs\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.Template = Template;
  var Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    ariaLabel: "Code de validation d'adresse e-mail"
  };
  var argTypes = {
    'ariaLabel': {
      name: 'ariaLabel',
      description: "L'aria-label de chaque champ",
      type: {
        name: 'string',
        required: true
      }
    },
    inputType: {
      name: 'inputType',
      description: "Le type de chaque champ : `number` ou `text`",
      type: {
        name: 'string',
        required: false
      },
      options: ['number', 'text'],
      control: {
        type: 'select'
      },
      table: {
        defaultValue: {
          summary: 'number'
        }
      }
    },
    numInputs: {
      name: 'numInputs',
      description: "Le nombre de champ",
      type: {
        name: 'number',
        required: false
      },
      table: {
        defaultValue: {
          summary: 6
        }
      }
    },
    onAllInputsFilled: {
      name: 'onAllInputsFilled',
      description: 'fonction appeler une fois que tous les champs ont été rempli avec le code en parametre',
      type: {
        required: false
      },
      control: {
        disable: true
      }
    }
  };
  _exports.argTypes = argTypes;
});