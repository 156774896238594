define("ember-models-table/templates/components/models-table/cell-edit-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fFPkHmRS",
    "block": "[[[41,[30,0,[\"isEditRow\"]],[[[1,\"  \"],[10,\"button\"],[15,0,[28,[37,1],[[30,0,[\"themeInstance\",\"buttonDefault\"]],\" \",[30,0,[\"themeInstance\",\"cancelRowButton\"]]],null]],[15,\"onclick\",[30,0,[\"cancelClicked\"]]],[14,4,\"button\"],[12],[1,\"\\n    \"],[1,[30,0,[\"cancelButtonLabel\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"button\"],[15,0,[28,[37,1],[[30,0,[\"themeInstance\",\"buttonDefault\"]],\" \",[30,0,[\"themeInstance\",\"saveRowButton\"]]],null]],[15,\"onclick\",[30,0,[\"saveClicked\"]]],[14,4,\"button\"],[12],[1,\"\\n    \"],[1,[30,0,[\"saveButtonLabel\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"button\"],[15,0,[28,[37,1],[[30,0,[\"themeInstance\",\"buttonDefault\"]],\" \",[30,0,[\"themeInstance\",\"editRowButton\"]]],null]],[15,\"onclick\",[30,0,[\"editClicked\"]]],[14,4,\"button\"],[12],[1,\"\\n    \"],[1,[30,0,[\"editButtonLabel\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[18,1,null]],[\"&default\"],false,[\"if\",\"concat\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/cell-edit-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});