define("ember-models-table/templates/components/models-table/cell-column-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kwQwWJXp",
    "block": "[[[18,1,[[28,[37,1],null,[[\"minSelected\",\"maxSelected\",\"sumSelected\",\"avgSelected\",\"medianSelected\",\"minData\",\"maxData\",\"sumData\",\"avgData\",\"medianData\"],[[30,0,[\"minSelected\"]],[30,0,[\"maxSelected\"]],[30,0,[\"sumSelected\"]],[30,0,[\"avgSelected\"]],[30,0,[\"medianSelected\"]],[30,0,[\"minData\"]],[30,0,[\"maxData\"]],[30,0,[\"sumData\"]],[30,0,[\"avgData\"]],[30,0,[\"medianData\"]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/cell-column-summary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});