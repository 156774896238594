define("pix-ui/stories/pix-multi-select.stories", ["exports", "@storybook/addon-actions"], function (_exports, _addonActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.multiSelectSearchable = _exports.multiSelectWithChildComponent = void 0;

  var multiSelectWithChildComponent = function multiSelectWithChildComponent(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <h3>⚠️ Pour voir le réel rendu, importez PixMultiSelect dans votre app</h3>
            <PixMultiSelect
              @title={{titleStars}}
              @id={{id}}
              @onSelect={{onSelect}}
              @emptyMessage={{emptyMessage}}
              @label={{label}}
              @options={{options}} as |star|
            >
              <PixStars
                @count={{star.value}}
                @total={{star.total}}
              />
            </PixMultiSelect>
          
      */
      {
        "id": "hp9uiWGD",
        "block": "[[[1,\"\\n      \"],[10,\"h3\"],[12],[1,\"⚠️ Pour voir le réel rendu, importez PixMultiSelect dans votre app\"],[13],[1,\"\\n      \"],[8,[39,0],null,[[\"@title\",\"@id\",\"@onSelect\",\"@emptyMessage\",\"@label\",\"@options\"],[[36,1],[36,2],[36,3],[36,4],[36,5],[36,6]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,7],null,[[\"@count\",\"@total\"],[[30,1,[\"value\"]],[30,1,[\"total\"]]]],null],[1,\"\\n      \"]],[1]]]]],[1,\"\\n    \"]],[\"star\"],false,[\"pix-multi-select\",\"titleStars\",\"id\",\"onSelect\",\"emptyMessage\",\"label\",\"options\",\"pix-stars\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.multiSelectWithChildComponent = multiSelectWithChildComponent;
  multiSelectWithChildComponent.args = {
    titleStars: 'Sélectionner le niveau souhaité',
    options: [{
      value: '1',
      total: 3
    }, {
      value: '2',
      total: 3
    }, {
      value: '3',
      total: 3
    }]
  };

  var multiSelectSearchable = function multiSelectSearchable(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <h3>⚠️ Pour voir le réel rendu, importez PixMultiSelect dans votre app</h3>
            <PixMultiSelect
              style="width:350px"
              @id={{id}}
              @title={{title}}
              @placeholder={{placeholder}}
              @isSearchable={{isSearchable}}
              @showOptionsOnInput={{showOptionsOnInput}}
              @strictSearch={{strictSearch}}
              @onSelect={{doSomething}}
              @emptyMessage={{emptyMessage}}
              @size={{size}}
              @selected={{selected}}
              @options={{options}} as |option|
            >
              {{option.label}}
            </PixMultiSelect>
          
      */
      {
        "id": "WkJJP28D",
        "block": "[[[1,\"\\n      \"],[10,\"h3\"],[12],[1,\"⚠️ Pour voir le réel rendu, importez PixMultiSelect dans votre app\"],[13],[1,\"\\n      \"],[8,[39,0],[[24,5,\"width:350px\"]],[[\"@id\",\"@title\",\"@placeholder\",\"@isSearchable\",\"@showOptionsOnInput\",\"@strictSearch\",\"@onSelect\",\"@emptyMessage\",\"@size\",\"@selected\",\"@options\"],[[36,1],[36,2],[36,3],[36,4],[36,5],[36,6],[36,7],[36,8],[36,9],[36,10],[36,11]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n      \"]],[1]]]]],[1,\"\\n    \"]],[\"option\"],false,[\"pix-multi-select\",\"id\",\"title\",\"placeholder\",\"isSearchable\",\"showOptionsOnInput\",\"strictSearch\",\"doSomething\",\"emptyMessage\",\"size\",\"selected\",\"options\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.multiSelectSearchable = multiSelectSearchable;
  var argTypes = {
    id: {
      name: 'id',
      description: 'Permet l‘accessibilité du composant attribuant des ``for`` pour chaque entité',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: 'aromate'
    },
    title: {
      name: 'title',
      description: 'Donne un titre à sa liste de choix multiple.',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: 'Rechercher un condiment'
    },
    label: {
      name: 'label',
      description: 'Donne un label au champ, le paramètre @id devient obligatoire avec ce paramètre.',
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    },
    emptyMessage: {
      name: 'emptyMessage',
      description: 'Un intitulé de choix indisponible (dans le cas ou certains filtres seraient excluant)',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: 'pas de résultat'
    },
    placeholder: {
      name: 'placeholder',
      description: 'Donner une liste d‘exemple pour la recherche utilisateur dans le cas ``isSearchable`` à ``true``',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: 'Curcuma, Thym, ...'
    },
    options: {
      name: 'options',
      description: 'Les options sont représentées par un tableau d‘objet contenant les propriétés ``value`` et ``label``. ``value`` doit être de type ``String`` pour être conforme au traitement des input value.',
      type: {
        name: 'array',
        required: true
      },
      defaultValue: [{
        label: "ANETH HERBE AROMATIQUE",
        value: '1'
      }, {
        label: "ANIS VERT HERBE AROMATIQUE",
        value: '2'
      }, {
        label: "BADIANE AROMATE",
        value: '3'
      }, {
        label: "BAIES ROSES EPICES",
        value: '4'
      }, {
        label: "BASILIC HERBE AROMATIQUE",
        value: '5'
      }, {
        label: "BOURRACHE OFFICINALE HERBE AROMATIQUE",
        value: '6'
      }, {
        label: "CANNELLE AROMATE",
        value: '7'
      }, {
        label: "CAPRE CONDIMENT",
        value: '8'
      }, {
        label: "CARDAMOME AROMATE",
        value: '9'
      }, {
        label: "CARVI HERBE AROMATIQUE",
        value: '10'
      }, {
        label: "CERFEUIL HERBE AROMATIQUE",
        value: '11'
      }]
    },
    onSelect: {
      name: 'onSelect',
      description: 'Une fonction permettant d\'effectuer une action à chaque sélection',
      type: {
        required: true
      },
      defaultValue: (0, _addonActions.action)('onSelect')
    },
    selected: {
      name: 'selected',
      description: 'Une pré-sélection peut être donnée au composant',
      type: {
        name: 'array',
        required: false
      },
      defaultValue: ['1', '4']
    },
    showOptionsOnInput: {
      name: 'showOptionsOnInput',
      description: 'Afficher la liste au focus du champs de saisie lorsque ``isSearchable`` à ``true``',
      type: {
        name: 'boolean',
        required: false
      },
      defaultValue: true
    },
    isSearchable: {
      name: 'isSearchable',
      description: 'Permet de rajouter une saisie utilisateur pour faciliter la recherche',
      type: {
        name: 'boolean',
        required: false
      },
      defaultValue: true
    },
    strictSearch: {
      name: 'strictSearch',
      description: 'Permet de rendre sensible à la casse et au diacritiques lorsque ``isSearchable`` à ``true``',
      type: {
        name: 'boolean',
        required: false
      },
      defaultValue: false
    },
    size: {
      name: 'size',
      description: '⚠️ **Propriété dépréciée** ⚠️ , désormais tous les éléments de formulaires feront 36px de hauteur.',
      options: ['big', 'small'],
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'small'
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});