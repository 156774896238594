define("pix-ui/stories/pix-tag.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argTypes = _exports.compactTag = _exports.tag = void 0;

  var tag = function tag(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixTag @color={{color}} @compact={{compact}}>
              Contenu du tag
            </PixTag>
          
      */
      {
        "id": "kaDpL7Ir",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@color\",\"@compact\"],[[36,1],[36,2]]],[[\"default\"],[[[[1,\"\\n        Contenu du tag\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-tag\",\"color\",\"compact\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.tag = tag;

  var compactTag = function compactTag(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixTag @color={{color}} @compact={{compact}}>
              Contenu du tag
            </PixTag>
          
      */
      {
        "id": "kaDpL7Ir",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@color\",\"@compact\"],[[36,1],[36,2]]],[[\"default\"],[[[[1,\"\\n        Contenu du tag\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-tag\",\"color\",\"compact\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.compactTag = compactTag;
  compactTag.args = {
    compact: true
  };
  var argTypes = {
    color: {
      name: 'color',
      description: 'Couleur du tag',
      type: {
        name: 'number',
        required: false
      },
      defaultValue: 'blue',
      control: {
        type: 'select',
        options: ['blue', 'blue-light', 'purple', 'purple-light', 'green', 'green-light', 'yellow', 'yellow-light', 'grey', 'grey-light']
      }
    },
    compact: {
      name: 'compact',
      description: 'Tag compact ou non',
      type: {
        name: 'boolean',
        required: false
      },
      defaultValue: false
    }
  };
  _exports.argTypes = argTypes;
});