define("pix-ui/stories/pix-banner.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.argsTypes = _exports.withInternalLink = _exports.withExternalLink = _exports.communicationPixCertif = _exports.communicationPixOrga = _exports.communicationPixApp = _exports.error = _exports.warning = _exports.Default = void 0;

  var Template = function Template(args) {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixBanner
              @type={{type}}
              @actionLabel={{actionLabel}}
              @actionUrl={{actionUrl}}
            >
              Parcours de rentrée 2020 : les codes sont disponibles dans l'onglet campagne. N’oubliez pas de les diffuser aux élèves avant la Toussaint.
            </PixBanner>
          
      */
      {
        "id": "Cefj0P0d",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@type\",\"@actionLabel\",\"@actionUrl\"],[[36,1],[36,2],[36,3]]],[[\"default\"],[[[[1,\"\\n        Parcours de rentrée 2020 : les codes sont disponibles dans l'onglet campagne. N’oubliez pas de les diffuser aux élèves avant la Toussaint.\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-banner\",\"type\",\"actionLabel\",\"actionUrl\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  var Default = Template.bind({});
  _exports.Default = Default;
  var warning = Template.bind({});
  _exports.warning = warning;
  warning.args = {
    type: 'warning'
  };
  var error = Template.bind({});
  _exports.error = error;
  error.args = {
    type: 'error'
  };
  var communicationPixApp = Template.bind({});
  _exports.communicationPixApp = communicationPixApp;
  communicationPixApp.args = {
    type: 'communication'
  };
  var communicationPixOrga = Template.bind({});
  _exports.communicationPixOrga = communicationPixOrga;
  communicationPixOrga.args = {
    type: 'communication-orga'
  };
  var communicationPixCertif = Template.bind({});
  _exports.communicationPixCertif = communicationPixCertif;
  communicationPixCertif.args = {
    type: 'communication-certif'
  };
  var withExternalLink = Template.bind({});
  _exports.withExternalLink = withExternalLink;
  withExternalLink.args = {
    type: 'info',
    actionLabel: 'Voir le nouveau site',
    actionUrl: 'www.test.fr/'
  };
  var withInternalLink = Template.bind({});
  _exports.withInternalLink = withInternalLink;
  withInternalLink.args = {
    type: 'info',
    actionLabel: 'Voir la liste des participants',
    actionUrl: 'campaign.list'
  };
  var argsTypes = {
    actionLabel: {
      name: 'actionLabel',
      description: 'Nom de l‘action',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: ''
    },
    actionUrl: {
      name: 'actionUrl',
      description: 'Lien de l‘action',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: ''
    },
    type: {
      name: 'type',
      description: 'Définit le type de bannière',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: {
        summary: 'information'
      },
      control: {
        type: 'select',
        options: ['information', 'warning', 'error', 'communication', 'communication-orga', 'communication-certif']
      }
    }
  };
  _exports.argsTypes = argsTypes;
});