define("ember-models-table/templates/components/models-table/expand-all-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lSAgYUCO",
    "block": "[[[10,\"button\"],[15,0,[28,[37,0],[[30,0,[\"themeInstance\",\"buttonLink\"]],\" \",[30,0,[\"themeInstance\",\"expandAllRows\"]]],null]],[15,\"onclick\",[28,[37,1],[[30,0,[\"doExpandAllRows\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n  \"],[10,\"i\"],[15,0,[30,0,[\"themeInstance\",\"expandAllRowsIcon\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"br\"],[12],[13],[1,\"\\n\"],[10,\"button\"],[15,0,[28,[37,0],[[30,0,[\"themeInstance\",\"buttonLink\"]],\" \",[30,0,[\"themeInstance\",\"collapseAllRows\"]]],null]],[15,\"onclick\",[28,[37,1],[[30,0,[\"doCollapseAllRows\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n  \"],[10,\"i\"],[15,0,[30,0,[\"themeInstance\",\"collapseAllRowsIcon\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"concat\",\"fn\",\"yield\"]]",
    "moduleName": "ember-models-table/templates/components/models-table/expand-all-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});